// ** Auth Endpoints
export default {
  loginToken: '6e4a0edd4efb0f8e87d86e9b1a0419f1',
  //  loginEndpoint: 'http://localhost:51946/Autenticacao/v1/Auth',
  loginEndpoint: 'https://hml.api1nas.com.br/Vendas/Autenticacao/v1/Auth',
  //loginEndpoint: 'https://prd.api1nas.com.br/Vendas/Autenticacao/v1/Auth', //PRODUÇÃO
  // loginEndpoint: 'https://apirelatorios.novoassist.com.br/vendas/Autenticacao/v1/Auth',

  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  //  apiVendasEndpoint: 'http://localhost:51946',
  apiVendasEndpoint: 'https://hml.api1nas.com.br/Vendas',
  //apiVendasEndpoint: 'https://prd.api1nas.com.br/Vendas', //PRODUÇÃO
  // apiVendasEndpoint: 'https://apirelatorios.novoassist.com.br/vendas',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'sessionToken',
  storageRefreshTokenKeyName: 'sessionTokenexpiration'
}
